import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { StorageService } from '@services/storage/storage.service';
import { Observable } from 'rxjs';
import { ASSETS_URL } from '@tokens/assets-url.token';
import { CurrentCompanyService } from '@services/current-company/current-company.service';
import { S3_BUCKET_URL } from '@tokens/s3-bucket-url.token';
import { QLIK_SENSE_BASE_URL } from '@tokens/qlik-sense-base-url.token';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  storageService = inject(StorageService);
  currentCompany = inject(CurrentCompanyService);

  private assetsUrl = inject(ASSETS_URL);
  private s3BucketUrl = inject(S3_BUCKET_URL);
  private qlikSenseBaseUrl = inject(QLIK_SENSE_BASE_URL);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      req.url.includes(this.assetsUrl) ||
      req.url.startsWith(this.s3BucketUrl) ||
      req.url.startsWith(this.qlikSenseBaseUrl)
    ) {
      return next.handle(req);
    }

    const token = this.storageService.getToken();
    const currentCompany = this.currentCompany.systemName();

    let headers = req.headers
      .set('Accept', req.headers.get('Accept') || 'application/json')
      .set('authorization', token ? `Bearer ${token}` : '');

    if (headers.get('Company-System-Name') === '' && currentCompany) {
      headers = headers.set('Company-System-Name', currentCompany);
    }

    // for uploading files we dont need to set Content-Type header
    // if you change this, check users import functionality
    if (!(req.body instanceof FormData)) {
      headers = headers.set('Content-Type', req.headers.get('Content-Type') || 'application/json');
    }

    const authReq = req.clone({ headers });
    return next.handle(authReq);
  }
}
