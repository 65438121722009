import { effect, inject, Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '@tokens/api-endpoints.token';
import { CurrentCompanyService } from '@services/current-company/current-company.service';
import { Notification } from '@type/notification.type';
import { Company } from '@type/company.type';
import { Paginable } from '@type/paginable';
import { joinUrls } from '@utils/urls';
import { ActionCableConnection, ActionCableService } from '@services/action-cable/action-cable.service';

export type WebSocketMessage = {
  type?: string;
  identifier?: string;
  command?: string;
  message?: unknown;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  static WS_NOTIFICATION_CHANNEL = 'Noticed::NotificationChannel';

  protected http = inject(HttpClient);
  protected endpoints = inject(API_ENDPOINTS);
  protected currentCompany = inject(CurrentCompanyService);
  protected actionCableService = inject(ActionCableService);

  protected newNotificationSubject = new Subject<Notification>();
  protected notificationsChangeSubject = new Subject<void>();

  newNotification = this.newNotificationSubject.asObservable();
  notificationsChange = this.notificationsChangeSubject.asObservable();

  protected actionCableConnection?: ActionCableConnection;

  companyEffect = effect(
    () => {
      if (this.currentCompany.base()) {
        this.connectWebSocket();
      } else {
        this.disconnectWebSocket();
      }
    },
    { allowSignalWrites: true },
  );

  getPage(page: number = 1, companySystemName: Company['system_name'] = ''): Observable<Paginable<Notification>> {
    const params: Record<string, string | number> = {
      page: page,
    };

    return this.http.get<Paginable<Notification>>(joinUrls(this.endpoints.notifications), {
      params: params,
      headers: {
        'Company-System-Name': companySystemName,
      },
    });
  }

  getUnreadCount(companySystemName: Company['system_name'] = ''): Observable<{ count: number }> {
    return this.http.get<{ count: number }>(joinUrls(this.endpoints.notifications, 'notifications_count'), {
      headers: {
        'Company-System-Name': companySystemName,
      },
    });
  }

  markAsRead(id: number, companySystemName: Company['system_name'] = ''): Observable<Notification> {
    return this.http
      .post<Notification>(
        joinUrls(this.endpoints.notifications, id.toString(), 'mark_as_read'),
        {},
        {
          headers: {
            'Company-System-Name': companySystemName,
          },
        },
      )
      .pipe(tap(() => this.notificationsChangeSubject.next()));
  }

  markAllAsRead(companySystemName: Company['system_name'] = ''): Observable<Record<string, never>> {
    return this.http
      .post<Record<string, never>>(
        joinUrls(this.endpoints.notifications, 'mark_all_as_read'),
        {},
        {
          headers: {
            'Company-System-Name': companySystemName,
          },
        },
      )
      .pipe(tap(() => this.notificationsChangeSubject.next()));
  }

  protected connectWebSocket() {
    this.actionCableConnection = this.actionCableService.createConnection(NotificationService.WS_NOTIFICATION_CHANNEL);
    this.actionCableConnection.onMessage().subscribe({
      next: (message) => this.handleWebSocketNotification(message),
      error: (err) => console.error(err),
    });
  }

  protected disconnectWebSocket() {
    this.actionCableConnection?.close();
    delete this.actionCableConnection;
  }

  protected handleWebSocketNotification(message: WebSocketMessage) {
    const notification = message.message as Notification;
    if (notification) {
      this.notificationsChangeSubject.next();
      this.newNotificationSubject.next(notification);
    }
  }
}
