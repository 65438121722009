import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@type/user.type';

export const getUserShortName = (value: Pick<User, 'first_name' | 'last_name'>): string => {
  if (!value) return '';
  const firstName = value.first_name ? value.first_name : '';
  const lastNameInitial = value.last_name ? value.last_name.charAt(0).toUpperCase() + '.' : '';
  return `${firstName} ${lastNameInitial}`;
};

@Pipe({
  name: 'userShortName',
  standalone: true,
})
export class UserShortNamePipe implements PipeTransform {
  transform(value: Pick<User, 'first_name' | 'last_name'>): string {
    return getUserShortName(value);
  }
}
