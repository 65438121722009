import { inject, Injectable } from '@angular/core';
import { StorageService } from '@services/storage/storage.service';
import { CurrentCompanyService } from '@services/current-company/current-company.service';
import { webSocket } from 'rxjs/webSocket';
import { WebSocketSubject } from 'rxjs/internal/observable/dom/WebSocketSubject';
import { WS_URL } from '@tokens/ws-url.token';
import { Observable, Subject } from 'rxjs';

export type WebSocketMessage = {
  type?: string;
  identifier?: string;
  command?: string;
  message?: unknown;
};

export class ActionCableConnection<T extends WebSocketMessage = WebSocketMessage> {
  protected channel: string;
  protected wsSubject: WebSocketSubject<WebSocketMessage>;
  protected messageSubject = new Subject<T>();

  protected url: string;
  protected token?: string | null;
  protected companySystemName?: string | null;
  protected data?: unknown;

  constructor({
    url,
    token,
    companySystemName,
    channel,
    data,
  }: {
    url: string;
    token?: string | null;
    companySystemName?: string | null;
    channel: string;
    data?: unknown;
  }) {
    this.url = url;
    this.token = token;
    this.companySystemName = companySystemName;
    this.channel = channel;
    this.data = data;
    this.wsSubject = webSocket({ url: this.getUrl() });
    this.wsSubject.subscribe({
      next: (message) => this.handleWebSocketMessage(message),
      error: (err) => console.error(err),
    });
    this.open();
  }

  protected getUrl() {
    const params = new URLSearchParams({
      token: this.token || '',
      company_system_name: this.companySystemName || '',
    });
    return `${this.url}?${params.toString()}`;
  }

  protected handleWebSocketMessage(message: WebSocketMessage) {
    try {
      const identifier = JSON.parse(message.identifier || 'null');
      if (this.channel === identifier.channel && message.type !== 'confirm_subscription') {
        this.messageSubject.next(message as T);
      }
    } catch (e) {
      /* empty */
    }
  }

  protected open(): void {
    this.wsSubject.next({
      command: 'subscribe',
      identifier: JSON.stringify({ channel: this.channel, ...(this.data || {}) }),
    });
  }

  onMessage(): Observable<T> {
    return this.messageSubject.asObservable();
  }

  close() {
    this.wsSubject.complete();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ActionCableService {
  protected storageService = inject(StorageService);
  protected currentCompany = inject(CurrentCompanyService);
  protected wsUrl = inject(WS_URL);

  createConnection<T extends WebSocketMessage = WebSocketMessage>(channel: string, data?: unknown) {
    return new ActionCableConnection<T>({
      url: this.wsUrl,
      token: this.storageService.getToken(),
      companySystemName: this.currentCompany.systemName(),
      channel,
      data,
    });
  }
}
