import { Pipe, PipeTransform } from '@angular/core';

export const titleForTruncatedStr = (value: string, maxLength: number): string => {
  // if value is truncated, use full value for title
  return value.length <= maxLength ? '' : value;
};

@Pipe({
  name: 'titleForTrunc',
  standalone: true,
})
export class TitleForTruncatePipe implements PipeTransform {
  transform(value: string, maxLength: number): string {
    return titleForTruncatedStr(value, maxLength);
  }
}
