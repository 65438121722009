import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { BEARER_TOKEN, TOKEN } from '@services/auth/param-names';
import { StorageScheme } from '@services/storage/storage-scheme.type';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  protected defaultView = inject(DOCUMENT).defaultView;
  protected localStoragePrefix = 'lf_sa_'; // leafio super app panel

  setToken(token: string): void {
    localStorage.setItem(BEARER_TOKEN, token);
  }

  getToken(): string | null {
    return localStorage.getItem(BEARER_TOKEN)?.replace('Bearer ', '') || null;
  }

  clearToken() {
    [BEARER_TOKEN].forEach((t) => localStorage.removeItem(t));
  }

  init() {
    let needToReload = false;

    needToReload = this.setTokenFromUrl() || needToReload;

    if (needToReload) {
      this.reloadWithoutParams();
    }
  }

  private setTokenFromUrl(): boolean {
    if (!this.getToken() && this.defaultView) {
      const token = new URLSearchParams(this.defaultView.location.search).get(TOKEN);
      if (token) {
        this.setToken(token);
        return true;
      }
    }
    return false;
  }

  private reloadWithoutParams() {
    this.defaultView?.history.replaceState({}, this.defaultView.document.title, this.defaultView.location.pathname);
  }

  protected getFullKeyName(key: keyof StorageScheme): string {
    return this.localStoragePrefix + key;
  }

  set<T extends keyof StorageScheme>(key: T, value: StorageScheme[T]): void {
    this.defaultView?.localStorage.setItem(this.getFullKeyName(key), JSON.stringify(value));
  }
  get<T extends keyof StorageScheme>(key: T): StorageScheme[T] | null {
    if (!this.defaultView) {
      return null;
    }
    const rawValue = this.defaultView.localStorage.getItem(this.getFullKeyName(key));

    if (rawValue === null) {
      return null;
    }

    try {
      return JSON.parse(rawValue) as StorageScheme[T];
    } catch (e) {
      return null;
    }
  }
  clear<T extends keyof StorageScheme>(key: T): void {
    this.defaultView?.localStorage.removeItem(this.getFullKeyName(key));
  }
}
