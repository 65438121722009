import { Pipe, PipeTransform } from '@angular/core';

export const truncateStr = (value: string, maxLength: number, truncationString: string = '...'): string => {
  if (value.length <= maxLength) {
    return value;
  }

  return value.substring(0, maxLength - truncationString.length) + truncationString;
};

@Pipe({
  name: 'trunc',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, maxLength: number, truncationString: string = '...'): string {
    return truncateStr(value, maxLength, truncationString);
  }
}
