import { computed, inject, Injectable } from '@angular/core';
import { CurrentCompanyService } from '@services/current-company/current-company.service';
import { AclService } from '@acl/service/acl.service';

@Injectable({
  providedIn: 'root',
})
export class PathResolverService {
  protected aclService = inject(AclService);
  protected currentCompany = inject(CurrentCompanyService);

  company = computed(() => this.currentCompany.base());

  getPrefix(): string {
    return this.aclService.isUserInternal() ? `company/${this.company()?.system_name}/` : '';
  }

  getPath(...parts: Array<string | number>): string {
    return this.getPrefix() + parts.join('/');
  }
}
