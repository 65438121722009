import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DEFAULT_APP_LOCALE } from '@tokens/default-app-locale.const';
import { UrlInterceptor } from '@interceptors/url.interceptor';
import { HeaderInterceptor } from '@interceptors/header.interceptor';
import { AuthInterceptor } from '@interceptors/auth.interceptor';
import { LfLoaderService } from '@leafio/ui/loader';
import { LfScrollbarStrategyService } from '@leafio/ui/utils';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DialogModule } from '@angular/cdk/dialog';
import { TitleStrategy } from '@angular/router';
import { TranslatePageTitleStrategy } from './core/page-title-strategy/translate-page-title-strategy';
import { StorageService } from '@services/storage/storage.service';
import { LF_TRIM_INPUTS } from '@leafio/ui/forms';
import { ProfileService } from '@services/profile/profile.service';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { environment } from '@environments/environment';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { LF_PAGINATOR_CONFIG, LfPaginatorIntlService } from '@leafio/ui/paginator';
import { PAGINATION_CONFIG } from '@constants/pagination-config';
import { PaginationIntlService } from '@components/pagination/pagination-intl-service';
import { LF_DEFAULT_TOAST_TITLES } from '@leafio/ui/toasts';
import { TOAST_TITLES } from '@constants/toast-titles';
import { NotificationHelperService } from '@services/notification-helper/notification-helper.service';

const translateConfig = {
  defaultLanguage: DEFAULT_APP_LOCALE,
  loader: {
    provide: TranslateLoader,
    useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
    deps: [HttpClient],
  },
};

const INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
];

const INITIALIZERS = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [StorageService],
    useFactory: (service: StorageService) => () => service.init(),
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [ProfileService],
    useFactory: (service: ProfileService) => () => service.initialize(),
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [LfLoaderService],
    useFactory: (service: LfLoaderService) => () =>
      service.init({
        hidingDelay: 100,
      }),
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [NotificationHelperService],
    useFactory: (service: NotificationHelperService) => () => service.init(),
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [LfScrollbarStrategyService],
    useFactory: (service: LfScrollbarStrategyService) => () => service.use('auto'),
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [AnalyticsService],
    useFactory: (analyticsService: AnalyticsService) => () => analyticsService.init(),
  },
];

function getFirebaseProviders() {
  if (!environment.firebaseConfig) {
    return [];
  }

  return [provideFirebaseApp(() => initializeApp(environment.firebaseConfig!)), provideAnalytics(() => getAnalytics())];
}

const PROVIDERS = [
  { provide: TitleStrategy, useClass: TranslatePageTitleStrategy },
  { provide: LF_TRIM_INPUTS, useValue: true },
  { provide: LF_PAGINATOR_CONFIG, useValue: PAGINATION_CONFIG },
  { provide: LfPaginatorIntlService, useClass: PaginationIntlService },
  { provide: LF_DEFAULT_TOAST_TITLES, useValue: TOAST_TITLES },
  ...getFirebaseProviders(),
];

export const CORE_PROVIDERS: (EnvironmentProviders | Provider)[] = [
  ...INTERCEPTORS,
  ...INITIALIZERS,
  ...PROVIDERS,
  provideHttpClient(withInterceptorsFromDi()),
  importProvidersFrom(DialogModule),
  importProvidersFrom(TranslateModule.forRoot(translateConfig)),
  provideAnimations(),
];
