import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { NotificationService } from '@services/notification/notification.service';
import { CurrentCompanyService } from '@services/current-company/current-company.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { firstValueFrom } from 'rxjs';
import { Notification } from '@type/notification.type';
import { TranslateService } from '@ngx-translate/core';
import { truncateStr } from '@utils/truncate/truncate.pipe';
import { getUserShortName } from '@utils/user-short-name/user-short-name.pipe';
import { titleForTruncatedStr } from '@utils/title-for-truncate/title-for-truncate.pipe';
import { LfIcon } from '@leafio/ui/icons';
import { LfAccentWithNeutral } from '@leafio/ui/utils';
import { LfToastService } from '@leafio/ui/toasts';
import { Router } from '@angular/router';
import { PathResolverService } from '@services/path-resolver/path-resolver.service';

type NotificationContentResolver = string | ((notification: Notification) => string) | undefined;

const MAX_ISSUE_NAME_FOR_NOTIFICATION = 60;
const MAX_COMMENT_BODY_FOR_NOTIFICATION = 100;
const NOTIFICATION_TOAST_DURATION = 5000;

const NOTIFICATION_ICONS: Record<Notification['type'], LfIcon> = {
  new_issue: 'file',
  blocked_issue: 'block',
  upcoming_day_deadline_issue: 'clock_countdown',
  upcoming_hour_deadline_issue: 'clock_countdown',
  canceled_issue: 'close',
  completed_issue: 'check-circle',
  overdue_issue: 'siren',
  unblocked_issue: 'unlock_check',
  new_comment: 'comment',
  changed_assignee: 'arrow_right_double_curved',
};

const NOTIFICATION_ACCENTS: Record<Notification['type'], LfAccentWithNeutral> = {
  new_issue: 'brand',
  blocked_issue: 'danger',
  upcoming_day_deadline_issue: 'danger',
  upcoming_hour_deadline_issue: 'danger',
  canceled_issue: 'neutral',
  completed_issue: 'success',
  overdue_issue: 'attention',
  unblocked_issue: 'brand',
  new_comment: 'neutral',
  changed_assignee: 'brand',
};

const NOTIFICATION_TITLES: Partial<Record<Notification['type'], NotificationContentResolver>> = {
  upcoming_day_deadline_issue: 'notifications.titles.upcoming_deadline',
  upcoming_hour_deadline_issue: 'notifications.titles.upcoming_deadline',
};

const NOTIFICATION_ACTIONS: Partial<Record<Notification['type'], NotificationContentResolver>> = {
  new_issue: 'buttons.go_to_task',
  blocked_issue: 'buttons.go_to_task',
  upcoming_day_deadline_issue: 'buttons.go_to_task',
  upcoming_hour_deadline_issue: 'buttons.go_to_task',
  canceled_issue: 'buttons.go_to_task',
  completed_issue: 'buttons.go_to_task',
  overdue_issue: 'buttons.go_to_task',
  unblocked_issue: 'buttons.go_to_task',
  new_comment: 'buttons.go_to_task',
};

const NOTIFICATION_CONTENT: Partial<Record<Notification['type'], NotificationContentResolver>> = {
  blocked_issue: (notification) =>
    'performer' in notification && notification.performer
      ? 'notifications.content.blocked_issue'
      : 'notifications.content.blocked_issue_without_performer',
  unblocked_issue: (notification) =>
    'performer' in notification && notification.performer
      ? 'notifications.content.unblocked_issue'
      : 'notifications.content.unblocked_issue_without_performer',
  canceled_issue: (notification) =>
    'performer' in notification && notification.performer
      ? 'notifications.content.canceled_issue'
      : 'notifications.content.canceled_issue_without_performer',
};

export const TASK_NOTIFICATIONS: Array<Notification['type']> = [
  'new_issue',
  'blocked_issue',
  'upcoming_day_deadline_issue',
  'upcoming_hour_deadline_issue',
  'canceled_issue',
  'completed_issue',
  'overdue_issue',
  'unblocked_issue',
  'new_comment',
  'changed_assignee',
];

@Injectable({
  providedIn: 'root',
})
export class NotificationHelperService {
  protected destroyRef = inject(DestroyRef);
  protected notificationService = inject(NotificationService);
  protected currentCompany = inject(CurrentCompanyService);
  protected translate = inject(TranslateService);
  protected toastService = inject(LfToastService);
  protected router = inject(Router);
  protected pathResolverService = inject(PathResolverService);

  menuIndicatorCounter = signal(0);

  init() {
    this.notificationService.notificationsChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      void this.updateMenuUnreadCounter();
    });
    this.notificationService.newNotification.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((notification) => {
      void this.showNotificationToast(notification);
    });
    this.currentCompany.baseChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((company) => {
      if (company) {
        void this.updateMenuUnreadCounter();
      } else {
        this.menuIndicatorCounter.set(0);
      }
    });
  }

  showNotificationToast(notification: Notification) {
    const interpolateParams = this.getInterpolateParams(notification);
    this.toastService
      .show({
        icon: this.getNotificationIcon(notification),
        type: this.getNotificationAccent(notification),
        text: this.getNotificationContent(notification, interpolateParams),
        title: this.getNotificationTitle(notification, interpolateParams),
        action: this.getNotificationAction(notification),
        duration: NOTIFICATION_TOAST_DURATION,
      })
      .onAction?.subscribe(() => this.performNotificationAction(notification));
  }

  async updateMenuUnreadCounter() {
    try {
      const response = await firstValueFrom(this.notificationService.getUnreadCount());
      this.menuIndicatorCounter.set(response.count);
    } catch (e) {
      /* empty */
    }
  }

  protected resolveNotificationContent(
    map: Partial<Record<Notification['type'], NotificationContentResolver>>,
    notification: Notification,
  ): string | undefined {
    if (typeof map[notification.type] === 'function') {
      return (map[notification.type] as (notification: Notification) => string)(notification);
    }
    return map[notification.type] as string | undefined;
  }

  getInterpolateParams(notification: Notification) {
    const interpolateParams: Record<string, string> = {};

    if ('issue' in notification.data && notification.data.issue) {
      interpolateParams['issue_short_name'] = truncateStr(
        notification.data.issue.title,
        MAX_ISSUE_NAME_FOR_NOTIFICATION,
      );
      interpolateParams['issue_full_name'] = titleForTruncatedStr(
        notification.data.issue.title,
        MAX_ISSUE_NAME_FOR_NOTIFICATION,
      );

      interpolateParams['issue_type'] = this.translate.instant(`tasks.task_types.${notification.data.issue.type}`);
    }

    if ('performer' in notification) {
      interpolateParams['performer_short_name'] = notification.performer
        ? getUserShortName(notification.performer)
        : this.translate.instant('common.system');
      interpolateParams['performer_full_name'] = notification.performer?.full_name || 'common.system';
    }

    if ('comment' in notification.data) {
      interpolateParams['comment_short_body'] = truncateStr(
        notification.data.comment?.body || '',
        MAX_COMMENT_BODY_FOR_NOTIFICATION,
      );
      interpolateParams['comment_full_body'] = titleForTruncatedStr(
        notification.data.comment?.body || '',
        MAX_COMMENT_BODY_FOR_NOTIFICATION,
      );
    }

    return interpolateParams;
  }

  getNotificationIcon(notification: Notification): LfIcon {
    return NOTIFICATION_ICONS[notification.type] || 'check-circle';
  }

  getNotificationAccent(notification: Notification): LfAccentWithNeutral {
    return NOTIFICATION_ACCENTS[notification.type] || 'neutral';
  }

  getNotificationAction(notification: Notification) {
    return this.resolveNotificationContent(NOTIFICATION_ACTIONS, notification);
  }

  getNotificationTitle(notification: Notification, interpolateParams: Record<string, string>) {
    return this.translate.instant(
      this.resolveNotificationContent(NOTIFICATION_TITLES, notification) || `notifications.titles.${notification.type}`,
      interpolateParams,
    );
  }

  getNotificationContent(notification: Notification, interpolateParams: Record<string, string>) {
    return this.translate.instant(
      this.resolveNotificationContent(NOTIFICATION_CONTENT, notification) ||
        `notifications.content.${notification.type}`,
      interpolateParams,
    );
  }

  async performNotificationAction(notification: Notification) {
    try {
      if (TASK_NOTIFICATIONS.includes(notification.type)) {
        void this.router.navigate([this.pathResolverService.getPath('tasks', notification.data.issue.id)]);
      }
      void firstValueFrom(this.notificationService.markAsRead(notification.id));
    } catch (e) {
      /* empty */
    }
  }
}
