import * as i0 from '@angular/core';
import { inject, ElementRef, Directive, InjectionToken, HostBinding, HostListener, Component, ChangeDetectionStrategy, Input, ViewChildren } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { LfCreateInjectorFn } from '@leafio/ui/utils';
import { ComponentPortal } from '@angular/cdk/portal';
import { LfSvgIconComponent } from '@leafio/ui/svg-icons';
import * as i1$1 from '@angular/common';
import { CommonModule, Location } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink, Router, NavigationEnd, RouterLinkActive } from '@angular/router';
import { filter, map } from 'rxjs';
import * as i1 from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { LfIconComponent } from '@leafio/ui/icons';
import { LfIconButton } from '@leafio/ui/buttons';
import { LfTabListComponent, LfTabComponent } from '@leafio/ui/tabs';
function LfSubmenuItemComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-icon", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("name", ctx_r0.menuItem.icon);
  }
}
function LfSubmenuItemComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-svg-icon", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("name", ctx_r0.menuItem.iconName);
  }
}
function LfSubmenuItemComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.menuItem.iconText);
  }
}
function LfSubmenuItemComponent_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-icon", 5);
  }
}
function LfSubmenuItemComponent_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-icon", 6);
  }
}
const _c0 = a0 => ({
  "panel-header_with-link": a0
});
function LfSubmenuComponent_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-icon", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("name", ctx_r0.menuItem.submenuHeader.icon);
  }
}
function LfSubmenuComponent_Conditional_0_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-svg-icon", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("name", ctx_r0.menuItem.submenuHeader.iconName);
  }
}
function LfSubmenuComponent_Conditional_0_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 1, ctx_r0.menuItem.submenuHeader.description), " ");
  }
}
function LfSubmenuComponent_Conditional_0_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-icon", 8);
  }
}
function LfSubmenuComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 0);
    i0.ɵɵtemplate(1, LfSubmenuComponent_Conditional_0_Conditional_1_Template, 1, 1, "lf-icon", 3)(2, LfSubmenuComponent_Conditional_0_Conditional_2_Template, 1, 1, "lf-svg-icon", 4);
    i0.ɵɵelementStart(3, "div", 5)(4, "div", 6);
    i0.ɵɵtext(5);
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, LfSubmenuComponent_Conditional_0_Conditional_7_Template, 3, 3, "div", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, LfSubmenuComponent_Conditional_0_Conditional_8_Template, 1, 0, "lf-icon", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("routerLink", ctx_r0.menuItem.submenuHeader.url)("ngClass", i0.ɵɵpureFunction1(9, _c0, ctx_r0.menuItem.submenuHeader.url !== null));
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.menuItem.submenuHeader.icon ? 1 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.menuItem.submenuHeader.iconName ? 2 : -1);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(6, 7, ctx_r0.menuItem.submenuHeader.name), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(ctx_r0.menuItem.submenuHeader.description ? 7 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.menuItem.submenuHeader.url !== null ? 8 : -1);
  }
}
function LfSubmenuComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵpipe(3, "uppercase");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 3, i0.ɵɵpipeBind1(2, 1, ctx_r0.menuItem.name)));
  }
}
function LfSubmenuComponent_Conditional_2_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-submenu-item", 9);
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    i0.ɵɵproperty("menuItem", item_r2);
  }
}
function LfSubmenuComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵrepeaterCreate(1, LfSubmenuComponent_Conditional_2_For_2_Template, 1, 1, "lf-submenu-item", 9, i0.ɵɵrepeaterTrackByIdentity);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵrepeater(ctx_r0.menuItem.children);
  }
}
const _c1 = a0 => ({
  exact: a0
});
function LfMenuItemComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-svg-icon", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("name", ctx_r0.menuItem.iconName);
  }
}
function LfMenuItemComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-icon", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("name", ctx_r0.menuItem.icon);
  }
}
function LfMenuItemComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.menuItem.iconText);
  }
}
function LfMenuItemComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("menu-item__badge_with-content", !!ctx_r0.badgeContent);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.badgeContent);
  }
}
function LfMainMenuComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-menu-item", 1);
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    i0.ɵɵproperty("menuItem", item_r1);
  }
}
function LfMainMenuComponent_For_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-menu-item", 1);
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    i0.ɵɵproperty("menuItem", item_r2);
  }
}
const _c2 = [[["", "lfPageHeaderSubtitle", ""]], [["", "lfPageHeaderTitle", ""]], "*"];
const _c3 = ["[lfPageHeaderSubtitle]", "[lfPageHeaderTitle]", "*"];
function LfPageHeader_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("click", function LfPageHeader_Conditional_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.back());
    });
    i0.ɵɵelementEnd();
  }
}
function LfPageHeader_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "translate");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, ctx_r1.subTitle), " ");
  }
}
function LfPageHeader_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "translate");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, ctx_r1.title), " ");
  }
}
const _c4 = a0 => [a0];
function LfMenuComponent_For_2_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-icon", 3);
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("name", item_r1.icon);
  }
}
function LfMenuComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 2, 0);
    i0.ɵɵtemplate(2, LfMenuComponent_For_2_Conditional_2_Template, 1, 1, "lf-icon", 3);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const link_r2 = i0.ɵɵreference(1);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(6, _c4, item_r1.url))("active", link_r2.isActive);
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(item_r1.icon ? 2 : -1);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(4, 4, item_r1.name), " ");
  }
}
class LfAbstractMenuDirective {
  constructor() {
    this.elementRef = inject(ElementRef);
  }
  closeSelf() {
    this.selfOverlayRef?.detach();
  }
  closeSelfAndParent() {
    this.closeSelf();
    this.parentItem?.menu.closeSelfAndParent();
  }
  closeChildren() {
    this.items?.forEach(item => {
      item.destroyOverlay();
    });
  }
  isNodeBelongsToOverlay(node) {
    return this.selfOverlayRef?.overlayElement.contains(node) || false;
  }
  isNodeBelongsToAnyItemOverlay(node) {
    return this.items?.some(item => item.isOverlayElementContainsNode(node)) || false;
  }
  isNodeBelongsToAnyParentOverlay(node) {
    return this.isNodeBelongsToOverlay(node) || this.parentItem?.menu.isNodeBelongsToAnyParentOverlay(node) || false;
  }
  static {
    this.ɵfac = function LfAbstractMenuDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LfAbstractMenuDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LfAbstractMenuDirective
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfAbstractMenuDirective, [{
    type: Directive
  }], null, null);
})();
const LF_MENU_ITEM = new InjectionToken('current menu item');
const LF_PARENT_MENU = new InjectionToken('parent menu instance');
class LfAbstractMenuItemDirective {
  constructor() {
    this.menu = inject(LfAbstractMenuDirective);
    this.createInjector = LfCreateInjectorFn();
    this.overlay = inject(Overlay);
    this.elementRef = inject(ElementRef);
  }
  get disabled() {
    return !!this.menuItem.disabled;
  }
  get isWithSubmenu() {
    return !this.disabled && (!!this.menuItem.submenuHeader || !!this.menuItem.children?.length);
  }
  get isWithSubmenuAsComponent() {
    return !!this.menuItem.menuComponent;
  }
  get isWithAnySubmenu() {
    return this.isWithSubmenu || this.isWithSubmenuAsComponent;
  }
  get needToCreateOverlay() {
    return this.isWithTooltip || this.isWithAnySubmenu;
  }
  get isWithTooltip() {
    return !this.isWithAnySubmenu && !!this.menuItem.name;
  }
  get hasOverlay() {
    return !!this.childOverlayRef?.hasAttached();
  }
  get hasAction() {
    return this.menuItem.url !== null || !!this.menuItem.onClick;
  }
  ngOnDestroy() {
    this.destroyOverlay();
  }
  mouseenter() {
    if (this.hasOverlay) {
      return;
    }
    if (this.needToCreateOverlay) {
      this.createOverlay();
    }
  }
  onClick(event) {
    this.menuItem.onClick?.(event);
  }
  mouseleave(event) {
    if (this.isWithAnySubmenu && event.relatedTarget) {
      if (!this.isOverlayElementContainsNode(event.relatedTarget)) {
        this.destroyOverlay();
      }
    } else {
      this.destroyOverlay();
    }
  }
  createOverlay() {
    this.childOverlayRef = this.overlay.create({
      positionStrategy: this.getOverlayPositionStrategy(),
      disposeOnNavigation: true
    });
    this.childOverlayRef.attach(this.createPortalForOverlay());
    return this.childOverlayRef;
  }
  destroyOverlay() {
    this.childOverlayRef?.detach();
  }
  isOverlayElementContainsNode(node) {
    return this.hasOverlay && !!this.childOverlayRef?.overlayElement.contains(node);
  }
  createPortalForOverlay() {
    return this.createComponentPortalWithInjector(this.getComponentForPortal());
  }
  createComponentPortalWithInjector(component) {
    return new ComponentPortal(component, null, this.createInjector([{
      provide: LF_MENU_ITEM,
      useValue: this.menuItem
    }, {
      provide: LF_PARENT_MENU,
      useValue: this
    }, {
      provide: OverlayRef,
      useValue: this.childOverlayRef
    }]));
  }
  getOverlayPositionStrategy() {
    const submenuPositions = [{
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top'
    }, {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom'
    }, {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'center'
    }];
    const tooltipPositions = [{
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: 6
    }];
    return this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions(this.isWithAnySubmenu ? submenuPositions : tooltipPositions);
  }
  static {
    this.ɵfac = function LfAbstractMenuItemDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LfAbstractMenuItemDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LfAbstractMenuItemDirective,
      hostVars: 2,
      hostBindings: function LfAbstractMenuItemDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseenter", function LfAbstractMenuItemDirective_mouseenter_HostBindingHandler() {
            return ctx.mouseenter();
          })("click", function LfAbstractMenuItemDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event);
          })("mouseleave", function LfAbstractMenuItemDirective_mouseleave_HostBindingHandler($event) {
            return ctx.mouseleave($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵclassProp("disabled", ctx.disabled);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfAbstractMenuItemDirective, [{
    type: Directive
  }], null, {
    disabled: [{
      type: HostBinding,
      args: ['class.disabled']
    }],
    mouseenter: [{
      type: HostListener,
      args: ['mouseenter']
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    mouseleave: [{
      type: HostListener,
      args: ['mouseleave', ['$event']]
    }]
  });
})();
class LfMenuTooltipComponent {
  constructor() {
    this.text = inject(LF_MENU_ITEM).name;
  }
  static {
    this.ɵfac = function LfMenuTooltipComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LfMenuTooltipComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfMenuTooltipComponent,
      selectors: [["lf-menu-tooltip"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 3,
      template: function LfMenuTooltipComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtext(0);
          i0.ɵɵpipe(1, "translate");
        }
        if (rf & 2) {
          i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind1(1, 1, ctx.text), "\n");
        }
      },
      dependencies: [CommonModule, TranslateModule, i1.TranslatePipe],
      styles: ["[_nghost-%COMP%]{position:relative;display:block;padding:8px 12px;background:var(--lf-bg-900);border-radius:4px;text-transform:uppercase;font:var(--lf-caption-font);color:var(--lf-text-100)}[_nghost-%COMP%]:before{content:\" \";position:absolute;left:-4px;top:calc(50% - 4px);display:block;width:8px;height:8px;background:var(--lf-bg-900);transform:rotate(45deg)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfMenuTooltipComponent, [{
    type: Component,
    args: [{
      selector: 'lf-menu-tooltip',
      standalone: true,
      imports: [CommonModule, TranslateModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "{{ text | translate }}\n",
      styles: [":host{position:relative;display:block;padding:8px 12px;background:var(--lf-bg-900);border-radius:4px;text-transform:uppercase;font:var(--lf-caption-font);color:var(--lf-text-100)}:host:before{content:\" \";position:absolute;left:-4px;top:calc(50% - 4px);display:block;width:8px;height:8px;background:var(--lf-bg-900);transform:rotate(45deg)}\n"]
    }]
  }], null, null);
})();
class LfSubmenuItemComponent extends LfAbstractMenuItemDirective {
  constructor() {
    super(...arguments);
    this.submenu = inject(LfSubmenuComponent);
  }
  get hostClasses() {
    let result = '';
    if (this.menuItem.accent) {
      result += ' with-accent lf-' + this.menuItem.accent;
    }
    if (this.isWithAnySubmenu) {
      result += ' with-submenu';
    }
    if (this.menuItem.checked) {
      result += ' checked';
    }
    return result;
  }
  get isWithTooltip() {
    return false;
  }
  getComponentForPortal() {
    return this.menuItem.menuComponent || LfSubmenuComponent;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLfSubmenuItemComponent_BaseFactory;
      return function LfSubmenuItemComponent_Factory(__ngFactoryType__) {
        return (ɵLfSubmenuItemComponent_BaseFactory || (ɵLfSubmenuItemComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LfSubmenuItemComponent)))(__ngFactoryType__ || LfSubmenuItemComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfSubmenuItemComponent,
      selectors: [["lf-submenu-item"]],
      hostVars: 2,
      hostBindings: function LfSubmenuItemComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClasses);
        }
      },
      inputs: {
        menuItem: "menuItem"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 9,
      vars: 9,
      consts: [[1, "item-wrapper", 3, "routerLink"], [1, "item-icon", 3, "name"], ["width", "24px", "height", "24px", 1, "item-icon", "item-icon_svg", 3, "name"], [1, "item-icon", "item-icon_text"], [1, "item-text"], ["name", "check_bold", 1, "checked-icon"], ["name", "caret_right", 1, "submenu-icon"]],
      template: function LfSubmenuItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, LfSubmenuItemComponent_Conditional_1_Template, 1, 1, "lf-icon", 1)(2, LfSubmenuItemComponent_Conditional_2_Template, 1, 1, "lf-svg-icon", 2)(3, LfSubmenuItemComponent_Conditional_3_Template, 2, 1, "span", 3);
          i0.ɵɵelementStart(4, "div", 4);
          i0.ɵɵtext(5);
          i0.ɵɵpipe(6, "translate");
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(7, LfSubmenuItemComponent_Conditional_7_Template, 1, 0, "lf-icon", 5)(8, LfSubmenuItemComponent_Conditional_8_Template, 1, 0, "lf-icon", 6);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("item-wrapper_with-link", ctx.hasAction);
          i0.ɵɵproperty("routerLink", ctx.menuItem.url);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.menuItem.icon ? 1 : ctx.menuItem.iconName ? 2 : ctx.menuItem.iconText ? 3 : -1);
          i0.ɵɵadvance(4);
          i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(6, 7, ctx.menuItem.name), " ");
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.menuItem.checked ? 7 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.isWithAnySubmenu ? 8 : -1);
        }
      },
      dependencies: [CommonModule, LfIconComponent, LfSvgIconComponent, TranslateModule, i1.TranslatePipe, RouterLink],
      styles: ["[_nghost-%COMP%]{display:block;background-color:var(--lf-bg-100);transition-duration:var(--lf-transition-duration);transition-property:background-color}[_nghost-%COMP%]:hover:not(.disabled){background-color:var(--lf-brand-100)}.checked[_nghost-%COMP%]{background-color:var(--lf-current-accent-100, var(--lf-brand-100))}.checked[_nghost-%COMP%]   .checked-icon[_ngcontent-%COMP%], .checked[_nghost-%COMP%]   .item-icon[_ngcontent-%COMP%], .checked[_nghost-%COMP%]   .submenu-icon[_ngcontent-%COMP%]{color:var(--lf-current-accent-500, var(--lf-brand-500))}.with-accent[_nghost-%COMP%]   .item-text[_ngcontent-%COMP%], .with-accent[_nghost-%COMP%]   .item-icon[_ngcontent-%COMP%], .with-accent[_nghost-%COMP%]   .submenu-icon[_ngcontent-%COMP%]{color:var(--lf-current-accent-500)}.with-accent[_nghost-%COMP%]:hover:not(.disabled){background-color:var(--lf-current-accent-100)}.item-wrapper[_ngcontent-%COMP%]{display:flex;align-items:center;padding:5px 12px;min-height:42px;gap:8px}.item-wrapper.item-wrapper_with-link[_ngcontent-%COMP%]{cursor:pointer}.disabled[_nghost-%COMP%]{cursor:not-allowed}.disabled[_nghost-%COMP%]   .item-wrapper[_ngcontent-%COMP%]{opacity:.4;pointer-events:none}.item-text[_ngcontent-%COMP%]{font:var(--lf-subtitle-font);color:var(--lf-text-900);flex:1 1 auto}.item-icon[_ngcontent-%COMP%]{flex:0 0 auto;min-width:var(--lf-main-menu-icon-size);font-size:var(--lf-main-menu-icon-size);color:var(--lf-main-900);text-align:center}.item-icon_text[_ngcontent-%COMP%]{font-family:var(--lf-font-family-secondary);font-size:var(--lf-main-menu-icon-as-text-size);font-style:normal;font-weight:700;line-height:72%;-webkit-user-select:none;user-select:none}.submenu-icon[_ngcontent-%COMP%]{flex:0 0 auto;font-size:var(--lf-main-submenu-secondary-icon-size);color:var(--lf-main-900)}.checked-icon[_ngcontent-%COMP%]{flex:0 0 auto;display:inline-flex;font-size:var(--lf-main-submenu-secondary-icon-size)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfSubmenuItemComponent, [{
    type: Component,
    args: [{
      selector: 'lf-submenu-item',
      standalone: true,
      imports: [CommonModule, LfIconComponent, LfSvgIconComponent, TranslateModule, RouterLink],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"item-wrapper\" [class.item-wrapper_with-link]=\"hasAction\" [routerLink]=\"menuItem.url\">\n  @if (menuItem.icon) {\n    <lf-icon class=\"item-icon\" [name]=\"menuItem.icon\"></lf-icon>\n  } @else if (menuItem.iconName) {\n    <lf-svg-icon [name]=\"menuItem.iconName\"\n                 width=\"24px\"\n                 height=\"24px\"\n                 class=\"item-icon item-icon_svg\"\n    ></lf-svg-icon>\n  } @else if (menuItem.iconText) {\n    <span class=\"item-icon item-icon_text\">{{ menuItem.iconText }}</span>\n  }\n  <div class=\"item-text\">\n    {{ menuItem.name | translate }}\n  </div>\n  @if (menuItem.checked) {\n    <lf-icon class=\"checked-icon\" name=\"check_bold\"></lf-icon>\n  }\n  @if (isWithAnySubmenu) {\n    <lf-icon class=\"submenu-icon\" name=\"caret_right\"></lf-icon>\n  }\n</div>\n",
      styles: [":host{display:block;background-color:var(--lf-bg-100);transition-duration:var(--lf-transition-duration);transition-property:background-color}:host:hover:not(.disabled){background-color:var(--lf-brand-100)}:host.checked{background-color:var(--lf-current-accent-100, var(--lf-brand-100))}:host.checked .checked-icon,:host.checked .item-icon,:host.checked .submenu-icon{color:var(--lf-current-accent-500, var(--lf-brand-500))}:host.with-accent .item-text,:host.with-accent .item-icon,:host.with-accent .submenu-icon{color:var(--lf-current-accent-500)}:host.with-accent:hover:not(.disabled){background-color:var(--lf-current-accent-100)}.item-wrapper{display:flex;align-items:center;padding:5px 12px;min-height:42px;gap:8px}.item-wrapper.item-wrapper_with-link{cursor:pointer}:host.disabled{cursor:not-allowed}:host.disabled .item-wrapper{opacity:.4;pointer-events:none}.item-text{font:var(--lf-subtitle-font);color:var(--lf-text-900);flex:1 1 auto}.item-icon{flex:0 0 auto;min-width:var(--lf-main-menu-icon-size);font-size:var(--lf-main-menu-icon-size);color:var(--lf-main-900);text-align:center}.item-icon_text{font-family:var(--lf-font-family-secondary);font-size:var(--lf-main-menu-icon-as-text-size);font-style:normal;font-weight:700;line-height:72%;-webkit-user-select:none;user-select:none}.submenu-icon{flex:0 0 auto;font-size:var(--lf-main-submenu-secondary-icon-size);color:var(--lf-main-900)}.checked-icon{flex:0 0 auto;display:inline-flex;font-size:var(--lf-main-submenu-secondary-icon-size)}\n"]
    }]
  }], null, {
    menuItem: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class LfSubmenuComponent extends LfAbstractMenuDirective {
  constructor() {
    super(...arguments);
    this.menuItem = inject(LF_MENU_ITEM);
    this.parentItem = inject(LF_PARENT_MENU);
    this.selfOverlayRef = inject(OverlayRef);
  }
  click() {
    this.closeSelfAndParent();
    this.closeChildren();
  }
  mouseleave(event) {
    if (!event.relatedTarget) {
      return this.closeSelfAndParent();
    }
    if (this.isNodeBelongsToAnyItemOverlay(event.relatedTarget)) {
      return;
    }
    if (this.isNodeBelongsToAnyParentOverlay(event.relatedTarget)) {
      return this.closeSelf();
    }
    this.closeSelfAndParent();
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLfSubmenuComponent_BaseFactory;
      return function LfSubmenuComponent_Factory(__ngFactoryType__) {
        return (ɵLfSubmenuComponent_BaseFactory || (ɵLfSubmenuComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LfSubmenuComponent)))(__ngFactoryType__ || LfSubmenuComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfSubmenuComponent,
      selectors: [["lf-submenu"]],
      viewQuery: function LfSubmenuComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(LfSubmenuItemComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.items = _t);
        }
      },
      hostBindings: function LfSubmenuComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function LfSubmenuComponent_click_HostBindingHandler() {
            return ctx.click();
          })("mouseleave", function LfSubmenuComponent_mouseleave_HostBindingHandler($event) {
            return ctx.mouseleave($event);
          });
        }
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: LfAbstractMenuDirective,
        useExisting: LfSubmenuComponent
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 2,
      consts: [[1, "panel-header", 3, "routerLink", "ngClass"], [1, "base-header"], [1, "items"], [1, "panel-header__item-icon", 3, "name"], ["width", "24px", "height", "24px", 1, "panel-header_item-icon", "panel-header_item-icon_svg", 3, "name"], [1, "panel-header-text-wrapper"], [1, "panel-header-text", "panel-header-text_name"], [1, "panel-header-text", "panel-header-text_description"], ["name", "caret_right", 1, "panel-header-navigate-icon"], [3, "menuItem"]],
      template: function LfSubmenuComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, LfSubmenuComponent_Conditional_0_Template, 9, 11, "div", 0)(1, LfSubmenuComponent_Conditional_1_Template, 4, 5, "div", 1)(2, LfSubmenuComponent_Conditional_2_Template, 3, 0, "div", 2);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.menuItem.submenuHeader ? 0 : 1);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional((ctx.menuItem.children == null ? null : ctx.menuItem.children.length) ? 2 : -1);
        }
      },
      dependencies: [CommonModule, i1$1.NgClass, i1$1.UpperCasePipe, RouterLink, TranslateModule, i1.TranslatePipe, LfIconComponent, LfSvgIconComponent, LfSubmenuItemComponent],
      styles: ["[_nghost-%COMP%]{display:block;width:var(--lf-main-submenu-width);background:var(--lf-bg-100);box-shadow:var(--lf-elevation-300);border-radius:0 6px 6px 0;overflow:hidden}.base-header[_ngcontent-%COMP%]{padding:12px 16px;border-bottom:1px solid var(--lf-main-300);font:var(--lf-caption-font);color:var(--lf-text-500)}.panel-header[_ngcontent-%COMP%]{display:flex;align-items:center;padding:16px 12px;min-height:0;gap:8px;border-bottom:1px solid var(--lf-main-300);background-color:var(--lf-bg-300);transition-duration:var(--lf-transition-duration);transition-property:background-color}.panel-header.panel-header_with-link[_ngcontent-%COMP%]{cursor:pointer}.panel-header.panel-header_with-link[_ngcontent-%COMP%]:hover{background-color:var(--lf-main-300)}.panel-header__item-icon[_ngcontent-%COMP%]{flex:0 0 auto;font-size:var(--lf-main-menu-icon-size);color:var(--lf-main-900)}.panel-header-text-wrapper[_ngcontent-%COMP%]{flex:1 1 auto;overflow:hidden}.panel-header-text[_ngcontent-%COMP%]{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.panel-header-text.panel-header-text_name[_ngcontent-%COMP%]{font:var(--lf-subtitle-font);color:var(--lf-text-900)}.panel-header-text.panel-header-text_description[_ngcontent-%COMP%]{margin-top:2px;font:var(--lf-text-s-font);color:var(--lf-text-500)}.item-text[_ngcontent-%COMP%]{font:var(--lf-subtitle-font);color:var(--lf-text-900);flex:1 1 auto}.panel-header-navigate-icon[_ngcontent-%COMP%]{flex:0 0 auto;font-size:16px;color:var(--lf-main-900)}.items[_ngcontent-%COMP%]{gap:4px;margin-top:4px;margin-bottom:8px}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfSubmenuComponent, [{
    type: Component,
    args: [{
      selector: 'lf-submenu',
      standalone: true,
      imports: [CommonModule, RouterLink, TranslateModule, LfIconComponent, LfSvgIconComponent, LfSubmenuItemComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: LfAbstractMenuDirective,
        useExisting: LfSubmenuComponent
      }],
      template: "@if (menuItem.submenuHeader) {\n  <div class=\"panel-header\" [routerLink]=\"menuItem.submenuHeader.url\" [ngClass]=\"{ 'panel-header_with-link': menuItem.submenuHeader.url !== null }\">\n    @if (menuItem.submenuHeader.icon) {\n      <lf-icon class=\"panel-header__item-icon\" [name]=\"menuItem.submenuHeader.icon\"></lf-icon>\n    }\n    @if (menuItem.submenuHeader.iconName) {\n      <lf-svg-icon [name]=\"menuItem.submenuHeader.iconName\"\n                   width=\"24px\"\n                   height=\"24px\"\n                   class=\"panel-header_item-icon panel-header_item-icon_svg\"\n      ></lf-svg-icon>\n    }\n    <div class=\"panel-header-text-wrapper\">\n      <div class=\"panel-header-text panel-header-text_name\">\n        {{ menuItem.submenuHeader.name | translate }}\n      </div>\n      @if (menuItem.submenuHeader.description) {\n        <div class=\"panel-header-text panel-header-text_description\">\n          {{ menuItem.submenuHeader.description | translate }}\n        </div>\n      }\n    </div>\n    @if (menuItem.submenuHeader.url !== null) {\n      <lf-icon class=\"panel-header-navigate-icon\" name=\"caret_right\"></lf-icon>\n    }\n  </div>\n} @else {\n  <div class=\"base-header\">{{ menuItem.name | translate | uppercase }}</div>\n}\n@if (menuItem.children?.length) {\n  <div class=\"items\">\n    @for (item of menuItem.children; track item) {\n      <lf-submenu-item [menuItem]=\"item\"></lf-submenu-item>\n    }\n  </div>\n}\n",
      styles: [":host{display:block;width:var(--lf-main-submenu-width);background:var(--lf-bg-100);box-shadow:var(--lf-elevation-300);border-radius:0 6px 6px 0;overflow:hidden}.base-header{padding:12px 16px;border-bottom:1px solid var(--lf-main-300);font:var(--lf-caption-font);color:var(--lf-text-500)}.panel-header{display:flex;align-items:center;padding:16px 12px;min-height:0;gap:8px;border-bottom:1px solid var(--lf-main-300);background-color:var(--lf-bg-300);transition-duration:var(--lf-transition-duration);transition-property:background-color}.panel-header.panel-header_with-link{cursor:pointer}.panel-header.panel-header_with-link:hover{background-color:var(--lf-main-300)}.panel-header__item-icon{flex:0 0 auto;font-size:var(--lf-main-menu-icon-size);color:var(--lf-main-900)}.panel-header-text-wrapper{flex:1 1 auto;overflow:hidden}.panel-header-text{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.panel-header-text.panel-header-text_name{font:var(--lf-subtitle-font);color:var(--lf-text-900)}.panel-header-text.panel-header-text_description{margin-top:2px;font:var(--lf-text-s-font);color:var(--lf-text-500)}.item-text{font:var(--lf-subtitle-font);color:var(--lf-text-900);flex:1 1 auto}.panel-header-navigate-icon{flex:0 0 auto;font-size:16px;color:var(--lf-main-900)}.items{gap:4px;margin-top:4px;margin-bottom:8px}\n"]
    }]
  }], null, {
    items: [{
      type: ViewChildren,
      args: [LfSubmenuItemComponent]
    }],
    click: [{
      type: HostListener,
      args: ['click']
    }],
    mouseleave: [{
      type: HostListener,
      args: ['mouseleave', ['$event']]
    }]
  });
})();
class LfMenuItemComponent extends LfAbstractMenuItemDirective {
  constructor() {
    super(...arguments);
    this.isActive = toSignal(inject(Router).events.pipe(filter(event => event instanceof NavigationEnd), map(event => event.url), map(url => !!this.menuItem.children?.map(u => u.url).some(u => u !== null && url.includes(u)))), {
      initialValue: false
    });
  }
  get itemClasses() {
    let result = '';
    if (this.menuItem.className) {
      result += this.menuItem.className + ' ';
    }
    if (!this.menuItem.url && this.isActive()) {
      result += 'menu-item-active ';
    }
    if (this.hasAction) {
      result += 'menu-item_with-action ';
    }
    if (this.menuItem.badge) {
      result += 'menu-item_with-badge ';
    }
    return result;
  }
  get badgeContent() {
    if (typeof this.menuItem.badge === 'number') {
      return this.menuItem.badge.toString();
    }
    return this.menuItem.badge ? '' : undefined;
  }
  getComponentForPortal() {
    if (this.isWithTooltip) {
      return LfMenuTooltipComponent;
    }
    return this.menuItem.menuComponent || LfSubmenuComponent;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLfMenuItemComponent_BaseFactory;
      return function LfMenuItemComponent_Factory(__ngFactoryType__) {
        return (ɵLfMenuItemComponent_BaseFactory || (ɵLfMenuItemComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LfMenuItemComponent)))(__ngFactoryType__ || LfMenuItemComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfMenuItemComponent,
      selectors: [["lf-menu-item"]],
      inputs: {
        menuItem: "menuItem"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 7,
      consts: [["routerLinkActive", "menu-item-active", 1, "menu-item", 3, "ngClass", "routerLink", "routerLinkActiveOptions"], ["width", "1em", "height", "1em", 1, "item-icon", "item-icon_svg", 3, "name"], [1, "item-icon", 3, "name"], [1, "item-icon", "item-icon_text"], [1, "menu-item__badge", 3, "menu-item__badge_with-content"], [1, "menu-item__badge"]],
      template: function LfMenuItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, LfMenuItemComponent_Conditional_1_Template, 1, 1, "lf-svg-icon", 1)(2, LfMenuItemComponent_Conditional_2_Template, 1, 1, "lf-icon", 2)(3, LfMenuItemComponent_Conditional_3_Template, 2, 1, "span", 3)(4, LfMenuItemComponent_Conditional_4_Template, 2, 3, "span", 4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.itemClasses)("routerLink", ctx.menuItem.url)("routerLinkActiveOptions", i0.ɵɵpureFunction1(5, _c1, ctx.menuItem.url === "/"));
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.menuItem.iconName ? 1 : ctx.menuItem.icon ? 2 : ctx.menuItem.iconText ? 3 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(ctx.menuItem.badge ? 4 : -1);
        }
      },
      dependencies: [CommonModule, i1$1.NgClass, LfSvgIconComponent, RouterLink, RouterLinkActive, LfIconComponent],
      styles: ["[_nghost-%COMP%]{display:block;position:relative}.menu-item[_ngcontent-%COMP%]{display:flex;align-items:center;justify-content:center;width:var(--lf-main-menu-width);height:var(--lf-main-menu-item-height);background-color:var(--lf-bg-900)}.menu-item[_ngcontent-%COMP%]:before{content:\"\";position:absolute;inset:0;pointer-events:none;background-color:var(--lf-bg-100);opacity:0;transition-duration:var(--lf-transition-duration);transition-property:opacity}.menu-item.menu-item_with-action[_ngcontent-%COMP%]{cursor:pointer}.menu-item.menu-item-active[_ngcontent-%COMP%]{background-color:var(--lf-brand-500)}.menu-item.menu-item-active[_ngcontent-%COMP%]   .item-icon_text[_ngcontent-%COMP%]{opacity:1}.menu-item[_ngcontent-%COMP%]:hover:before{opacity:.1}.disabled[_nghost-%COMP%]{cursor:not-allowed}.disabled[_nghost-%COMP%]   .menu-item[_ngcontent-%COMP%]{opacity:.4;pointer-events:none}.item-icon[_ngcontent-%COMP%]{color:var(--lf-text-100);font-size:var(--lf-main-menu-icon-size)}.item-icon_text[_ngcontent-%COMP%]{font-family:var(--lf-font-family-secondary);font-size:var(--lf-main-menu-icon-as-text-size);color:var(--lf-text-100);font-style:normal;font-weight:700;line-height:72%;-webkit-user-select:none;user-select:none;opacity:.4}.lf-menu-item_with-leafio-logo[_ngcontent-%COMP%]   .item-icon[_ngcontent-%COMP%]{font-size:48px}.menu-item__badge[_ngcontent-%COMP%]{position:absolute;left:50%;bottom:50%;min-height:var(--lf-main-menu-badge-min-size);min-width:var(--lf-main-menu-badge-min-size);max-width:50%;padding:var(--lf-main-menu-badge-padding);font:var(--lf-main-menu-badge-font);background-color:var(--lf-danger-500);color:var(--lf-danger-text);border-radius:var(--lf-main-menu-badge-min-size);overflow:hidden;text-overflow:ellipsis;text-align:center}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfMenuItemComponent, [{
    type: Component,
    args: [{
      selector: 'lf-menu-item',
      standalone: true,
      imports: [CommonModule, LfSvgIconComponent, RouterLink, RouterLinkActive, LfIconComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"menu-item\"\n     [ngClass]=\"itemClasses\"\n     [routerLink]=\"menuItem.url\"\n     [routerLinkActiveOptions]=\"{ exact: menuItem.url === '/' }\"\n     routerLinkActive=\"menu-item-active\"\n>\n  @if (menuItem.iconName) {\n    <lf-svg-icon class=\"item-icon item-icon_svg\" width=\"1em\" height=\"1em\" [name]=\"menuItem.iconName\" />\n  } @else if (menuItem.icon) {\n    <lf-icon class=\"item-icon\" [name]=\"menuItem.icon\"></lf-icon>\n  } @else if (menuItem.iconText) {\n    <span class=\"item-icon item-icon_text\">{{ menuItem.iconText }}</span>\n  }\n  @if (menuItem.badge) {\n    <span class=\"menu-item__badge\" [class.menu-item__badge_with-content]=\"!!badgeContent\">{{ badgeContent }}</span>\n  }\n</div>\n",
      styles: [":host{display:block;position:relative}.menu-item{display:flex;align-items:center;justify-content:center;width:var(--lf-main-menu-width);height:var(--lf-main-menu-item-height);background-color:var(--lf-bg-900)}.menu-item:before{content:\"\";position:absolute;inset:0;pointer-events:none;background-color:var(--lf-bg-100);opacity:0;transition-duration:var(--lf-transition-duration);transition-property:opacity}.menu-item.menu-item_with-action{cursor:pointer}.menu-item.menu-item-active{background-color:var(--lf-brand-500)}.menu-item.menu-item-active .item-icon_text{opacity:1}.menu-item:hover:before{opacity:.1}:host.disabled{cursor:not-allowed}:host.disabled .menu-item{opacity:.4;pointer-events:none}.item-icon{color:var(--lf-text-100);font-size:var(--lf-main-menu-icon-size)}.item-icon_text{font-family:var(--lf-font-family-secondary);font-size:var(--lf-main-menu-icon-as-text-size);color:var(--lf-text-100);font-style:normal;font-weight:700;line-height:72%;-webkit-user-select:none;user-select:none;opacity:.4}.lf-menu-item_with-leafio-logo .item-icon{font-size:48px}.menu-item__badge{position:absolute;left:50%;bottom:50%;min-height:var(--lf-main-menu-badge-min-size);min-width:var(--lf-main-menu-badge-min-size);max-width:50%;padding:var(--lf-main-menu-badge-padding);font:var(--lf-main-menu-badge-font);background-color:var(--lf-danger-500);color:var(--lf-danger-text);border-radius:var(--lf-main-menu-badge-min-size);overflow:hidden;text-overflow:ellipsis;text-align:center}\n"]
    }]
  }], null, {
    menuItem: [{
      type: Input,
      args: [{
        required: true
      }]
    }]
  });
})();
class LfMainMenuComponent extends LfAbstractMenuDirective {
  constructor() {
    super(...arguments);
    this.topItems = [];
    this.bottomItems = [];
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLfMainMenuComponent_BaseFactory;
      return function LfMainMenuComponent_Factory(__ngFactoryType__) {
        return (ɵLfMainMenuComponent_BaseFactory || (ɵLfMainMenuComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LfMainMenuComponent)))(__ngFactoryType__ || LfMainMenuComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfMainMenuComponent,
      selectors: [["lf-main-menu"]],
      viewQuery: function LfMainMenuComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(LfMenuItemComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.items = _t);
        }
      },
      inputs: {
        topItems: "topItems",
        bottomItems: "bottomItems"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: LfAbstractMenuDirective,
        useExisting: LfMainMenuComponent
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 6,
      vars: 0,
      consts: [[1, "menu-top"], [3, "menuItem"], [1, "menu-bottom"]],
      template: function LfMainMenuComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵrepeaterCreate(1, LfMainMenuComponent_For_2_Template, 1, 1, "lf-menu-item", 1, i0.ɵɵrepeaterTrackByIdentity);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 2);
          i0.ɵɵrepeaterCreate(4, LfMainMenuComponent_For_5_Template, 1, 1, "lf-menu-item", 1, i0.ɵɵrepeaterTrackByIdentity);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.topItems);
          i0.ɵɵadvance(3);
          i0.ɵɵrepeater(ctx.bottomItems);
        }
      },
      dependencies: [LfMenuItemComponent],
      styles: ["[_nghost-%COMP%]{display:flex;flex-direction:column;justify-content:space-between;align-items:center;height:100%;background-color:var(--lf-bg-900)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfMainMenuComponent, [{
    type: Component,
    args: [{
      selector: 'lf-main-menu',
      standalone: true,
      imports: [LfMenuItemComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: LfAbstractMenuDirective,
        useExisting: LfMainMenuComponent
      }],
      template: "<div class=\"menu-top\">\n  @for (item of topItems; track item) {\n    <lf-menu-item [menuItem]=\"item\"></lf-menu-item>\n  }\n</div>\n\n<div class=\"menu-bottom\">\n  @for (item of bottomItems; track item) {\n    <lf-menu-item [menuItem]=\"item\"></lf-menu-item>\n  }\n</div>\n",
      styles: [":host{display:flex;flex-direction:column;justify-content:space-between;align-items:center;height:100%;background-color:var(--lf-bg-900)}\n"]
    }]
  }], null, {
    topItems: [{
      type: Input
    }],
    bottomItems: [{
      type: Input
    }],
    items: [{
      type: ViewChildren,
      args: [LfMenuItemComponent]
    }]
  });
})();
class LfPageHeader {
  constructor() {
    this.title = '';
    this.subTitle = '';
    this.showBackBtn = false;
    this.location = inject(Location);
  }
  back() {
    this.location.back();
  }
  static {
    this.ɵfac = function LfPageHeader_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LfPageHeader)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfPageHeader,
      selectors: [["lf-page-header"]],
      inputs: {
        title: "title",
        subTitle: "subTitle",
        showBackBtn: "showBackBtn"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c3,
      decls: 12,
      vars: 3,
      consts: [[1, "header"], [1, "title-container"], ["lfIconBtn", "", "type", "button", "name", "arrow_left", "size", "s", "accent", "neutral"], [1, "header-title"], [1, "title", "header-title-item"], [1, "page-title", "header-title-item"], [1, "content-container"], ["lfIconBtn", "", "type", "button", "name", "arrow_left", "size", "s", "accent", "neutral", 3, "click"]],
      template: function LfPageHeader_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c2);
          i0.ɵɵelementStart(0, "header", 0)(1, "div", 1);
          i0.ɵɵtemplate(2, LfPageHeader_Conditional_2_Template, 1, 0, "button", 2);
          i0.ɵɵelementStart(3, "div", 3)(4, "p", 4);
          i0.ɵɵtemplate(5, LfPageHeader_Conditional_5_Template, 2, 3);
          i0.ɵɵprojection(6);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "h4", 5);
          i0.ɵɵtemplate(8, LfPageHeader_Conditional_8_Template, 2, 3);
          i0.ɵɵprojection(9, 1);
          i0.ɵɵelementEnd()()();
          i0.ɵɵelementStart(10, "div", 6);
          i0.ɵɵprojection(11, 2);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.showBackBtn ? 2 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(ctx.subTitle ? 5 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(ctx.title ? 8 : -1);
        }
      },
      dependencies: [CommonModule, TranslateModule, i1.TranslatePipe, LfIconButton],
      styles: [".header[_ngcontent-%COMP%]{display:flex;justify-content:space-between;align-items:center;min-height:var(--lf-page-header-height);padding:12px 20px;border-bottom:1px solid var(--lf-main-300);background-color:var(--lf-bg-100)}.header[_ngcontent-%COMP%]   .title-container[_ngcontent-%COMP%]{display:flex;align-items:center;gap:20px;color:var(--lf-text-500)}.header[_ngcontent-%COMP%]     .icon-btn.back-button{width:30px;height:30px;margin-right:16px;border:none}.header-title[_ngcontent-%COMP%]{display:flex;flex-flow:column nowrap}.header-title[_ngcontent-%COMP%]   .header-title-item[_ngcontent-%COMP%]:empty{display:none}.header-title[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]{font:var(--lf-caption-s-font);text-transform:uppercase;color:var(--lf-text-500);min-height:14px}.header-title[_ngcontent-%COMP%]   .page-title[_ngcontent-%COMP%]{font:var(--lf-h4-font);color:var(--lf-text-900);min-height:26px}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfPageHeader, [{
    type: Component,
    args: [{
      selector: 'lf-page-header',
      standalone: true,
      imports: [CommonModule, TranslateModule, RouterLink, RouterLinkActive, LfIconButton],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<header class=\"header\">\n  <div class=\"title-container\">\n    @if (showBackBtn) {\n      <button lfIconBtn\n              type=\"button\"\n              name=\"arrow_left\"\n              size=\"s\"\n              accent=\"neutral\"\n              (click)=\"back()\"\n      ></button>\n    }\n    <div class=\"header-title\">\n      <p class=\"title header-title-item\">\n        @if (subTitle) {\n          {{ subTitle | translate }}\n        }\n        <ng-content select=\"[lfPageHeaderSubtitle]\"></ng-content>\n      </p>\n      <h4 class=\"page-title header-title-item\">\n        @if (title) {\n          {{ title | translate }}\n        }\n        <ng-content select=\"[lfPageHeaderTitle]\"></ng-content>\n      </h4>\n    </div>\n  </div>\n  <div class=\"content-container\">\n    <ng-content></ng-content>\n  </div>\n</header>\n",
      styles: [".header{display:flex;justify-content:space-between;align-items:center;min-height:var(--lf-page-header-height);padding:12px 20px;border-bottom:1px solid var(--lf-main-300);background-color:var(--lf-bg-100)}.header .title-container{display:flex;align-items:center;gap:20px;color:var(--lf-text-500)}.header ::ng-deep .icon-btn.back-button{width:30px;height:30px;margin-right:16px;border:none}.header-title{display:flex;flex-flow:column nowrap}.header-title .header-title-item:empty{display:none}.header-title .title{font:var(--lf-caption-s-font);text-transform:uppercase;color:var(--lf-text-500);min-height:14px}.header-title .page-title{font:var(--lf-h4-font);color:var(--lf-text-900);min-height:26px}\n"]
    }]
  }], null, {
    title: [{
      type: Input
    }],
    subTitle: [{
      type: Input
    }],
    showBackBtn: [{
      type: Input
    }]
  });
})();
class LfMenuComponent {
  constructor() {
    this.items = [];
  }
  static {
    this.ɵfac = function LfMenuComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LfMenuComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfMenuComponent,
      selectors: [["lf-menu"]],
      inputs: {
        items: "items"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 0,
      consts: [["link", "routerLinkActive"], ["mode", "nav", "selectionMode", "manual"], ["lfTab", "", "routerLinkActive", "", 3, "routerLink", "active"], [3, "name"]],
      template: function LfMenuComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "lf-tab-list", 1);
          i0.ɵɵrepeaterCreate(1, LfMenuComponent_For_2_Template, 5, 8, "a", 2, i0.ɵɵrepeaterTrackByIdentity);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.items);
        }
      },
      dependencies: [CommonModule, TranslateModule, i1.TranslatePipe, RouterLink, RouterLinkActive, LfTabListComponent, LfTabComponent, LfIconComponent],
      styles: ["[_nghost-%COMP%]{display:block}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfMenuComponent, [{
    type: Component,
    args: [{
      selector: 'lf-menu',
      standalone: true,
      imports: [CommonModule, TranslateModule, RouterLink, RouterLinkActive, LfTabListComponent, LfTabComponent, LfIconComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<lf-tab-list mode=\"nav\" selectionMode=\"manual\">\n  @for (item of items; track item) {\n    <a lfTab [routerLink]=\"[item.url]\" routerLinkActive #link=\"routerLinkActive\" [active]=\"link.isActive\">\n      @if (item.icon) {\n        <lf-icon [name]=\"item.icon\"></lf-icon>\n      }\n      {{ item.name | translate }}\n    </a>\n  }\n</lf-tab-list>\n",
      styles: [":host{display:block}\n"]
    }]
  }], null, {
    items: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LF_MENU_ITEM, LF_PARENT_MENU, LfAbstractMenuDirective, LfAbstractMenuItemDirective, LfMainMenuComponent, LfMenuComponent, LfMenuItemComponent, LfMenuTooltipComponent, LfPageHeader, LfSubmenuComponent, LfSubmenuItemComponent };
